import styled from 'styled-components';
import media from 'styled-media-query';

export const ButtonHamburger = styled.button`
  background: transparent;
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 1rem;
  border: none;
  position: relative;
  margin-left: -1rem;
  ${media.greaterThan('medium')`
    display: none;
  `}

  &.active {
    span {
      background: transparent;
      &:before,
      &:after {
        top: 0;
        left: 0;
      }
      &:before {
        transform: rotate(-45deg);
      }
      &:after {
        transform: rotate(45deg);
      }
    }
  }
  div {
    width: 100%;
    height: 100%;
    position: relative;
  }

  span {
    background: var(--secondary-color);
    display: inline-block;
    height: 3px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &:before,
    &:after {
      content: '';
      background: var(--secondary-color);
      display: inline-block;
      height: 3px;
      width: 100%;
      position: absolute;
      left: 0;
      transition: transform 0.3s ease-in-out;
    }

    &:before {
      top: -7px;
    }

    &:after {
      bottom: -7px;
    }
  }
`;
