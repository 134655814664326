import { useStaticQuery, graphql } from 'gatsby';

function useTranslations(lang) {
  
  const locale = lang;
 
  // Query the JSON files in <rootDir>/i18n/translations
  const { rawData } = useStaticQuery(query);

  // Simplify the response from GraphQL
  const simplified = rawData.edges.map(item => {
    return {
      name: item.node.name,
      translations: item.node.translations,
    };
  });

  // Only return translations for the current locale
  const { translations } = simplified.filter(
    lang => lang.name === locale,
  )[0];

  return translations;
}

export default useTranslations;

const query = graphql`
  query useTranslations {
    rawData: allFile(
      filter: { sourceInstanceName: { eq: "translations" } }
    ) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            defaultDescription

            home
            news
            email

            latestPosts
            allPosts
            readMore
            by
            backTo

            view

            next
            prev
            of

            register{
              button
              url
            }            

            buttonLogin
            buttonLoginUrl           
            
            tryNowTitle
            tryNowText

            telephone
            
            productOfMaxdoro

            downloadApps
            downloadAppsText
            appStoreUrl
            playStoreUrl

            cookieConsent
            cookieReadMore
            cookieReadMoreUrl
            cookieButton
          }
        }
      }
    }
  }
`;
