import React from 'react';
import useMenuFooter from '../useMenuFooter';
import LogoWhite from '../LogoWhite';
import useTranslations from '../useTranslations';

import * as S from './styled';

const NavigationFooter = ({lang}) => {
  const menuItems = useMenuFooter(lang);
  const { home } = useTranslations(lang);  

  return (
    <>
      <S.NavigationFooter>

        <S.NavigationFooterItems>
        {menuItems.map((menu, index) => (
          <S.NavigationFooterLink
            to={menu.link}
            lang={lang}
            aria-label={menu.name}
            activeClassName="active"
            key={`${menu.link}${index}`}
            >
            {menu.name}
          </S.NavigationFooterLink>
        ))}
        </S.NavigationFooterItems>

        <S.NavigationFooterButton to="/" title={home} aria-label={home}>
          <LogoWhite />
        </S.NavigationFooterButton>

      </S.NavigationFooter>
    </>
  );
};

export default NavigationFooter;
