import styled from 'styled-components';
import media from 'styled-media-query';

export const TitleElement = styled.h2`
  line-height: 145%;
  font-size: 2.0rem;
  font-weight: 600;
  padding-bottom: var(--space);

  ${media.greaterThan('small')`
    font-size: 2.3rem;
  `}

`;
