import styled from 'styled-components';
import media from 'styled-media-query';
import LocalizedLink from '../LocalizedLink';

export const Navigation = styled.nav`
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  min-height: 80px;
  padding: 1rem 0;
  height: 100vh;
  ${media.greaterThan('medium')`
    height: auto;
    padding: 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}

  .react-typeform-embed {
    display: block!important;
  }
`;

export const NavigationLink = styled(LocalizedLink)`
  color: var(--text-color);
  text-decoration: none;
  font-weight: normal;
  display: block;
  padding: 1rem 0;
  position: relative;
  text-align: center;
  ${media.greaterThan('medium')`
    font-weight: 600;
    margin-right: 40px;
    display: inline-block;
  `}

  &:after {
    ${media.greaterThan('medium')`
      content: '';
      display: inline-block;
      width: 0;
      height: 2px;
      background: var(--action-color);
      position: absolute;
      left: 0;
      bottom: 0.75rem;
      opacity: 0;
      transition: width .3s ease-in-out;
    `}   
  }

  &:hover,
  &.active {
    font-weight: 600;
    color: var(--text-color);
    ${media.lessThan('medium')`
      text-decoration: underline;
    `}
    &:after {
      opacity: 1;
      bottom: 0.75rem;
      width: 100%;
    }
  }
`;

export const Buttons = styled.div`
  ${media.greaterThan('medium')`
    display: flex;
    align-items: center;
  `}
`;


export const LoginButton = styled.a`

${media.lessThan('medium')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  padding: 0 2.5rem;
  height: 44px;
  border-radius: 22px;
  background: transparent;
  border: 2px solid var(--action-color);
  color: var(--action-color);
  text-decoration: none;
  font-weight: 600;
  text-align: center;
  transition: background-color .3s ease-in-out;
  &:hover{
    color: var(--color-white);
    background-color: var(--action-color);
  } 
`}

${media.greaterThan('medium')`
  color: var(--text-color);
  text-decoration: none;
  margin: 1rem 0;
  padding: 1rem 0;
  position: relative;
  text-align: center;
  font-weight: 600;
  margin-right: 20px;
  display: inline-block;

  &:after {
    ${media.greaterThan('medium')`
      content: '';
      display: inline-block;
      width: 0;
      height: 2px;
      background: var(--action-color);
      position: absolute;
      left: 0;
      bottom: 0.75rem;
      opacity: 0;
      transition: width .3s ease-in-out;
    `}
  }

  &:hover,
  &.active {
    font-weight: 600;
    color: var(--text-color);
    ${media.greaterThan('medium')`
      font-weight: 600;
    `}
    &:after {
      opacity: 1;
      bottom: 0.75rem;
      width: 100%;
    }
}
`}
 
`;

export const NavigationButton = styled.button`
${media.lessThan('medium')`
  width: 100%;
`}
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2.5rem;
  height: 44px;
  border-radius: 22px;
  background: var(--action-color);
  color: var(--color-white);
  text-decoration: none;
  font-weight: 600;
  text-align: center;
  transition: background-color .3s ease-in-out;
  &:hover{
    color: var(--color-white);
    background-color: var(--action-color-hover);
  }  
`;