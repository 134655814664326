import React from 'react';
import * as S from './styled';

const Container = ({ ...props }) => {

  return (
    <S.Container {...props} />
  );
};

export default Container;
