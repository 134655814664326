import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  width: 100%;
  max-width: var(--width-container);
  margin: 5rem auto;
  padding: 0 var(--space);
  ${media.greaterThan('large')`
    margin: 7rem auto;
    padding: 0 var(--space-sm);
  `}
  ${media.lessThan('medium')`
    margin: 4rem auto;
`}     
`;