import styled from 'styled-components';
import media from 'styled-media-query';
import LocalizedLink from '../LocalizedLink';

export const HeaderWrapper = styled.div`
  height: 50px;
  ${media.greaterThan('medium')`
    height: 130px;
  `}

`;

export const Header = styled.div`
  position: fixed;
  width: 100%;
  z-index: 99;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
`;

export const Headertop = styled.div`
  background-color: var(--color-white);
  min-height: 50px;
  display: flex;
  justify-content: center;  
  box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.2);
  align-items: flex-end;
  ${media.lessThan('medium')`
  align-items: center;
`}
  `;
export const HeaderBottom = styled.div`
  background-color: var(--color-white);
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: var(--width-container);
  margin: 0 auto;
  align-items: center;
  padding: 0 var(--space);
  position: relative;
  ${media.greaterThan('large')`
    padding: 0 var(--space-sm);
    `}
  ${media.lessThan('medium')`
    justify-content: center;
    `}    
`;

export const ButtonMenu = styled.div`
  position: absolute;
  left: var(--space);
`;


export const LogoLink = styled(LocalizedLink)`
  height: 32px;
  ${media.lessThan('medium')`
    height: 22px;
    img {
      height: 22px;
    }
  `}
`;

export const NavMenu = styled.div`
  width: 100%;
  display: none;
  ${media.greaterThan('medium')`
    display: block;
  `}
  &.is-active {
    display: block;
  }
`;
