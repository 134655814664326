import React from "react";
import useMenu from "../useMenu";
import useTranslations from "../useTranslations";
import TryNowButton from "../TryNowButton";
import * as S from "./styled";

const Navigation = (props) => {
  const menuItems = useMenu(props.lang);
  const registerUrl = props.registerUrl
  const {
    buttonLogin,
    buttonLoginUrl,
  } = useTranslations(props.lang);

  return (
    <>
      <S.Navigation>
        <div>
          {menuItems.map((menu, index) => (
            <S.NavigationLink
              to={menu.link}
              lang={props.lang}
              aria-label={menu.name}
              activeClassName="active"
              partiallyActive={index === 0 ? false : true}
              key={`${menu.link}${index}`}
              onClick={props.handleClick}
            >
              {menu.name}
            </S.NavigationLink>
          ))}
        </div>

        <S.Buttons>
          <S.LoginButton
            href={buttonLoginUrl}
            target="_blank"
            onClick={props.handleClick}
          >
            {buttonLogin}
          </S.LoginButton>
          <TryNowButton lang={props.lang} registerUrl={registerUrl}/>
          
          
        </S.Buttons>

      </S.Navigation>
    </>
  );
};

export default Navigation;
