import React from 'react';

// import "@fontsource/open-sans/400.css"
// import "@fontsource/open-sans/600.css"

import GlobalStyles from '../styles/global';

import Header from '../components/Header';
import Footer from '../components/Footer';
import TryNow from '../components/TryNow';

import useTranslations from '../components/useTranslations';

import * as S from './styled';

const BaseLayout = ({ children, pageContext: { locale }, data }) => {

  const { 
    cookieConsent, 
    cookieButton,
    cookieReadMore, 
    cookieReadMoreUrl } = useTranslations(locale);

  const hasButton = 
    (data?.markdownRemark?.frontmatter?.button?.text &&
    data?.markdownRemark?.frontmatter?.button?.url) ||
    data?.markdownRemark?.frontmatter?.button?.typeFormId;
  const hideTryNow = data?.markdownRemark?.frontmatter?.hideTryNow == true; 

  const registerUrl = data?.pageData?.frontmatter?.registerUrl  

  return (
    <>
      <GlobalStyles />
      <S.Wrapper>
        <Header lang={locale} registerUrl={registerUrl} />
        <S.SiteContent role="main">
          {children}
        </S.SiteContent>

        {hasButton || hideTryNow ? null : <TryNow lang={locale} registerUrl={registerUrl} />}

        <Footer lang={locale} />

      </S.Wrapper>
    </>
  )
};

export { BaseLayout };
