// import React, { useEffect } from "react";
import React from "react";
import useTranslations from "../useTranslations";
// import Modal from "../Modal";
import * as S from "./styled";
// import RichText from '../RichText';

// import logoIncontrol from "../../images/logo-incontrol.svg";
// import image from "../../images/ola-to-incontrol.svg";

const TryNowButton = (props) => {
  const { lang, large, secondary, registerUrl } = props;
  const { register } = useTranslations(lang);
  // const [modalOpen, setModalOpen] = React.useState(false);
  const navUrl = registerUrl ? registerUrl : register.url

  // const url = new URL(navUrl)
  // const urlParams = new URLSearchParams(url.search);
  // const country = urlParams.get('country') ? urlParams.get('country').toLowerCase() : 'nl';

  // if (modalOpen) {
  //   useEffect(() => {
  //     document.body.style.overflow = "hidden";
  //   });
  // } else {
  //   useEffect(() => {
  //     document.body.style.overflow = "auto";
  //   });
  // }  

  return (
    <>
      {large ? (
        <S.ButtonLarge href={navUrl} target="_blank" rel="noopener noreferrer">
          {register.button}
        </S.ButtonLarge>
      ) : (
        <S.Button
          className={secondary ? 'secondary' : null}
          href={navUrl} target="_blank" rel="noopener noreferrer">{register.button}</S.Button>
      )}

      {/* {large ? (
        <S.ButtonLarge onClick={() => setModalOpen(true)}>
          {register.button}
        </S.ButtonLarge>
      ) : (
        <S.Button
          className={secondary ? 'secondary' : null}
          onClick={() => setModalOpen(true)}>{register.button}</S.Button>
      )}
      {modalOpen ? (
        <Modal setModalOpen={setModalOpen}>
          <S.All>

          <S.Content>
            <S.Title>
                Dé Opleverapp wordt Incontrol
            </S.Title>

            <S.Image src={image} alt="Dé Opleverapp → Incontrol" />
            <S.Text>
            {country === "be" ?
              
              <RichText style={{padding: `0`, textAlign: `justify` }}>
                  <p>Beste bezoeker,</p>
                  <p>Hartelijk dank voor uw interesse.</p>
                  <p>Vanaf heden zal Dé Opleverapp verder gaan onder de naam Incontrol. Ontdek nu de mogelijkheden van Incontrol en probeer alle formulieren en features voor 14 dagen gratis. Klik op onderstaande button en registreer u direct.</p>
                  <p>Fijne dag!</p>
                  <p><i>Team Incontrol</i></p>
              </RichText>
              :
              <RichText style={{padding: `0`, textAlign: `justify` }}>
                  <p>Beste bezoeker,</p>
                  <p>Hartelijk dank voor je interesse.</p>
                  <p>Vanaf heden zal Dé Opleverapp verder gaan onder de naam Incontrol. Ontdek nu de mogelijkheden van Incontrol en probeer alle formulieren en features voor 14 dagen gratis. Klik op onderstaande button en registreer je direct.</p>
                  <p>Fijne dag!</p>
                  <p><i>Team Incontrol</i></p>
              </RichText>
            }
            </S.Text>
          </S.Content>  

            <S.Logo src={logoIncontrol} alt="Incontrol" />
          
            <S.NavigationButton href={navUrl} target="_blank" rel="noopener noreferrer" >
              {register.button}
            </S.NavigationButton>

          </S.All>
        </Modal>
      ) : null} */}
    </>

  );
};

export default TryNowButton;
