// Only one item MUST have the "default: true" key

module.exports = {
  nl: {
    default: true,       
    path: `nl`,
    locale: `nl-NL`,
    dateFormat: `D MMMM YYYY`,
    siteLanguage: `nl`,
    ogLanguage: `nl-NL`,
    defaultTitle: `Gatsby Starter multilingue com CMS`,
    defaultDescription: `Exemplo de Gatsby site usando Markdown, i18n e CMS`,
  },
}
