import styled from 'styled-components';
import media from 'styled-media-query';

export const FooterWrapper = styled.div`
 background-color: var(--secondary-color); 
`;

export const FooterTop = styled.div`
  background-color: var(--secondary-color);
  color: var(--color-white);  
  padding: 6rem 0;
    p {
      margin: 1rem 0;
      font-size: 1.6rem;
      line-height: 160%;
    }

    a {
      color: var(--color-white);
      &:hover{
        color: var(--action-color)
      }
    }  
`;

export const FooterBottom = styled.div`
  background-color: var(--secondary-color-dark);
  color: var(--color-white);  
  padding: 2rem 0;
`;

export const FooterContainer = styled.div`
  width: 100%;
  max-width: var(--width-container);
  margin: 0 auto;
  padding: 0 var(--space);
  ${media.greaterThan('large')`
    padding: 0 var(--space-sm);
  `}
`;

export const FooterColumns = styled.div`
  ${media.greaterThan('medium')`
      display: flex;
  `}

`

export const FooterColumn = styled.div`
  ${media.greaterThan('medium')`
    flex: 1 1 0;
    &:first-child{
      padding-right: 2rem;
    }
    &:last-child{
      padding-left: 2rem;
    }
  `}  
`

export const ContactColumns = styled.div`
  ${media.greaterThan('large')`
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      `}

`

export const ContactColumn = styled.div`
  ${media.greaterThan('large')`
    padding-right: 4rem;
  `}  
`

export const FooterTitle = styled.h3`
  color: white;
  margin-top: 5rem;
  font-size: 2rem;
  font-weight: 600;
`;

export const LogoLink = styled.a`
  display: block;
  width: 140px;
  &:first-child{
    margin-right: 5px;
  }
`;

export const AppStores = styled.div`
    display: flex;
`;