import React from 'react';
import useTranslations from '../useTranslations';
import Container from '../Container';
import SubTitle from '../SubTitlePage';
import TryNowButton from '../TryNowButton';

import * as S from './styled';

const TryNow = ({lang, registerUrl}) => {

  const {
    tryNowTitle,
    tryNowText,
  } = useTranslations(lang);

  return (

    
    
    <S.Row>
    <Container>
      <S.TryNowWrapper>
        <SubTitle text={tryNowTitle}/>
        <p dangerouslySetInnerHTML={{ __html: tryNowText }}/>

        <TryNowButton lang={lang} large={true} registerUrl={registerUrl}/>

      </S.TryNowWrapper>
    </Container>
   </S.Row>

  );
};

export default TryNow;
